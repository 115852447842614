<template>
  <div class="dropdown_form col-12">
    <template v-if="type !== 'file'">
      <label :for="id">{{ label }}</label>
      <the-mask
        v-if="mask.length"
        class="input_text"
        :class="inputClass"
        :type="type"
        :value="value"
        :id="id"
        :mask="mask"
        :masked="masked"
        @input="$event => $emit('input', $event)"
        :readonly="readonly"
        :autocomplete="autocomplete"
      />
      <money
        class="input_text"
        :class="inputClass"
        v-else-if="type === 'money'"
        :value="value"
        v-bind="money"
        @input="$event => $emit('input', $event)"
        :autocomplete="autocomplete"
      />
      <input
        v-else
        class="input_text"
        :class="inputClass"
        :type="type"
        :value="value"
        :id="id"
        @input="$event => $emit('input', $event.target.value)"
        :readonly="readonly"
        :autocomplete="autocomplete"
      />
    </template>
    <template v-else>
      <label :for="id">{{ label }}</label>
      <input :id="id" hidden type="file" @input="inputFile" />
      <label class="input_text" :id="`file-label-${id}`" :for="id">
        {{ fileName }}
      </label>
    </template>
    <small class="input-error"> {{ error }}</small>
    <small class="input-info"> {{ info }}</small>
  </div>
</template>

<script>
import Vue from 'vue'
import { v4 } from 'uuid'
import { TheMask } from 'vue-the-mask'
import { Money } from 'v-money'

export default Vue.extend({
  components: { TheMask, Money },
  props: {
    label: {
      type: String,
      required: false
    },
    info: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'off'
    },
    value: {
      required: true,
      default: ''
    },
    mask: {
      required: false,
      default: ''
    },
    masked: {
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    inputClass: String
  },

  methods: {
    inputFile (e) {
      const [file] = e.target.files
      this.$emit('input', file)
    }
  },

  data: () => ({
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      suffix: '',
      precision: 2,
      masked: false
    }
  }),

  computed: {
    id () {
      return `form-field-${v4()}`
    },
    fileName () {
      if (this.type !== 'file') return
      if (this.value && typeof this.value === 'object' && this.value.name) {
        return this.value.name
      }
      return 'Selecionar...'
    },
    error () {
      if (!this.rules) return ''
      if (!this.$formSended && !this.value) return ''

      for (const rule of this.rules) {
        const result = rule(this.value)
        if (result !== true) return result
      }
      return ''
    }
  }
})
</script>

<style scoped>
.dropdown_form {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.input_text {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
  border-radius: 0.5rem;
}
.input-error {
  color: #ff6e91;
  font-size: 0.9rem;
  padding-left: 0.25rem;
}
.input-info {
  color: #ababab;
  font-size: 0.9rem;
  padding-left: 0.25rem;
}
</style>
